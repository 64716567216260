import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { geocode } from "nominatim-browser";
import { firestore } from "./firebase";
import { index } from "./algolia";

export default function LocationInput({ address, city, venue, setAddress, setCity, setVenue, ...rest }) {
    const [value, setValue] = useState('');
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [venueSuggestions, setVenueSuggestions] = useState([]);

    useEffect(async () => {
        if(value) return;
        if(venue) {
            const document = await getDoc(doc(collection(firestore, 'organizers'), venue));
            setValue(document.data().name);
        } else if(address)
            setValue(address)
    }, [value, setValue, venue, address]);

    //const [address, setAddress] = useState(rest.address);
    //const [city, setCity] = useState(rest.city);
    //const [venue, setVenue] = useState(rest.venue);
    var venueName = null;

    //useEffect(() => onAddressChange(address), [address, onAddressChange]);
    //useEffect(() => onCityChange(city), [city, onCityChange]);
    //useEffect(() => onVenueChange(venue), [venue, onVenueChange]);

    useEffect(() => suggestionsVisible && value.length > 2 ? index.search(value, { filters: 'recordType:"organizer" AND type:"venue"' }).then(({ hits }) => setVenueSuggestions(hits)) : setVenueSuggestions([]), [suggestionsVisible, value]);

    const onInputChange = (element) => {
        setValue(element.target.value);
        if(address === element.target.value || venueName === element.target.value) return;
        setAddress(null);
        setCity(null);
        setVenue(null);
        venueName = null;
    };

    const onVenueSuggestionClick = (venue) => {
        setValue(venue.name);
        setAddress(venue.address);
        setCity(venue.city);
        setVenue(venue.objectID.split('-')[1]);
        venueName = venue.name;
        setSuggestionsVisible(false);
    };

    const onBlur = async () => {
        setSuggestionsVisible(false);
        if(venueName === null) {
            const results = await geocode({
                q: value,
                addressdetails: true,
            });
            console.log(results);
            for(const result of results) {
                const address = result.address;
                if(address.road && address.postcode && (address.city || address.village) && address.country_code === 'de') {
                    let houseNumber = address.house_number;
                    if(!houseNumber) {
                        const germanAddressRegex = /\b(\d+)\s*([a-zA-Z]|\b)/; //ChatGPT did this, idk when it is working and when not
                        const houseNumberMatch = value.match(germanAddressRegex);
                        if (houseNumberMatch) {
                            houseNumber = houseNumberMatch[1];
                            console.log(houseNumber);
                        } else {
                            continue;
                        }
                    }
                    setValue(`${address.road} ${houseNumber}, ${address.postcode}, ${address.city??address.village}`);
                    setAddress(`${address.road} ${houseNumber}, ${address.postcode}, ${address.city??address.village}`);
                    setCity((address.city??address.village).toLowerCase());
                    break;
                }
            }
        }
    };

    return <>
        <input {...rest} style={{zIndex: 2}} value={value} onChange={(e) => onInputChange(e)} onFocus={() => setSuggestionsVisible(true)} onBlur={() => setTimeout(onBlur, 250)} onKeyDown={(e) => e.key === 'Enter' && e.target.blur()} />
        <div style={{position: 'relative'}}>
            {venueSuggestions.length > 0 && <div style={{ width: '100%', paddingTop: 20, backgroundColor: '#2c2c2c', color: 'white', position: 'absolute', zIndex: 1, cursor: 'pointer'}}>
                {venueSuggestions.map((e) => <VenueSuggestion name={e.name} onClick={() => onVenueSuggestionClick(e)} />)}
            </div>}
        </div>
    </>;
}

function VenueSuggestion({ name, onClick }) {
    return <div style={{ padding: 10}} onClick={onClick}>
        { name }
    </div>;
}