import React, { useEffect, useState } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import { index } from "./algolia";

export default function OrganizerInput({ organizer, setOrganizer, onSelect, ...rest }) {
    const [value, setValue] = useState('');
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    useEffect(async () => {
        if(value) return;
        if(organizer) {
            const document = await getDoc(doc(collection(firestore, 'organizers'), organizer));
            setValue(document.data().name);
        }
    }, [value, setValue, organizer]);

    var organizerName = null;

    useEffect(() => suggestionsVisible && value.length > 2 ? index.search(value, { filters: 'recordType:"organizer"', hitsPerPage: 5 }).then(({ hits }) => setSuggestions(hits.filter(e => e.name.replace(/[^A-Z0-9]+/ig, '').toLowerCase().startsWith(value.replace(/[^A-Z0-9]+/ig, '').toLowerCase())))) : setSuggestions([]), [suggestionsVisible, value]);

    const onInputChange = (element) => {
        setValue(element.target.value);
        if(value === element.target.value) return;
        setOrganizer(null);
        organizerName = null;
    };

    const onSuggestionClick = (e) => {
        onSelect && onSelect(e.objectID.split('-')[1]);
        setValue(e.name);
        setOrganizer(e.objectID.split('-')[1]);
        organizerName = e.name;
        setSuggestionsVisible(false);
    };

    const onBlur = async () => {
        if(value.length === 0 || organizerName) return;
        if(suggestions.length > 0 && suggestions[0].name.replace(/[^A-Z0-9]+/ig, '').toLowerCase().startsWith(value.replace(/[^A-Z0-9]+/ig, '').toLowerCase())) {
            onSuggestionClick(suggestions[0]);
        } else {
            onSelect && onSelect('$' + value);
            setOrganizer('$' + value);
            setSuggestionsVisible(false);
        }
    };

    const addAsNew = async() => {
        onSelect && onSelect('$' + value);
        setOrganizer('$' + value);
        organizerName = value;
        setSuggestionsVisible(false);
    };

    return <>
        <input {...rest} style={{zIndex: 2}} value={value} onChange={(e) => onInputChange(e)} onFocus={() => setSuggestionsVisible(true)} onBlur={() => setTimeout(onBlur, 250)} onKeyDown={e => e.key === 'Enter' && onBlur()} />
        <div style={{position: 'relative'}}>
            {value && suggestionsVisible && <div style={{ width: '100%', paddingTop: 20, backgroundColor: '#2c2c2c', color: 'white', position: 'absolute', zIndex: 1, cursor: 'pointer'}}>
                {suggestions.map((e) => <VenueSuggestion name={e.name} onClick={() => onSuggestionClick(e)} />)}
                {(suggestions.length === 0 || value.replace(/[^A-Z0-9]+/ig, '').toLowerCase() !== suggestions[0].name.replace(/[^A-Z0-9]+/ig, '').toLowerCase()) && <div style={{ padding: 10 }} onClick={addAsNew}>
                    "{value}" als neuen Veranstalter hinzufügen
                </div>}
            </div>}
        </div>
    </>;
}

function VenueSuggestion({ name, onClick }) {
    return <div style={{ padding: 10}} onClick={onClick}>
        { name }
    </div>;
}