import React, { useState,useEffect, useContext } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from "date-fns/locale/de";
import  {
  preview_image_event,
  delete_image_event,
  rechange_Text_color,
  change_Text_color,
  change_color_icon_Nav,
  rechange_color_icon_Nav,
} from "./erstellen/functions";
import { enableRipple } from "@syncfusion/ej2-base";
import LocationInput from "./LocationInput";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore, storage } from "./firebase";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Button, Modal } from "bootstrap";

import logo from "./logo.png";
import kitLogo from "./kit_logo.png";
import ArtistInput from "./ArtistInput";
import OrganizerInput from "./OrganizerInput";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

enableRipple(true);

registerLocale("de", de);

// List of Icons used
const titelbildIcon = (
  <i className="far fa-images" id="IconInField"></i>
);
const eventnameIcon = <i className="fas fa-signature" id="IconInField"></i>;
const datumIcon = <i className="fas fa-calendar" id="IconInField"></i>;
const adresseIcon = (
  <i
    className="fas fa-map-marked-alt"
    id="IconInField"
  ></i>
);
const detailsIcon = (
  <i
    className="fas fa-plus-circle"
    id="IconInField"
  ></i>
);
const genreIcon = <i className="fas fa-music" id="UnterIconInField"></i>;
const lineupIcon = <i class="fas fa-microphone" id="UnterIconInField"></i>;
const minAlterIcon = (
  <i className="fas fa-user-lock" id="UnterIconInField"></i>
  );
  const dresscodeIcon = (
    <i
    className="fas fa-tshirt fa-xs"
    id="UnterIconInField"
    ></i>
);

const getraenkeIcon = (
  <i
  className="fas fa-cocktail"
  id="UnterIconInField"
  ></i>
);

const eintrittIcon = (
  <i
    className="fas fa-ticket-alt"
    id="IconInField"
    ></i>
);

const beschreibungIcon = <i className="fas fa-edit" id="IconInField"></i>;
const zstAdresseIcon = (
  <i
    className="fas fa-map-marked-alt"
    id="IconInField"
  ></i>
  );

const storyLinkIcon = <i className="fas fa-link" id="IconInField"></i>;

const coVeranstalterIcon = (
  <i
    className="fas fa-users-cog"
    id="IconInField"
  ></i>
  );
  // End List of Icons used
  
  
  function EventErstellen() {
  return (
    <div>
      <EventErstellenContent></EventErstellenContent>
    </div>
  );
}
  

  function Inputs(props) {
    const l = useLocation();
    const [partner, setPartner] = useState(null);
    useEffect(() => setPartner(queryString.parse(l.search).partner), [l.search]);

    const [banner,setBanner] = useState(null);
    const [eventName,setEventName] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [state, setState] = useState(true);
    const [venue, setVenue] = useState(null);
    const [city, setCity] = useState(null);
    const [location,setLocation] = useState("");
    const [state2,setState2] = useState(true);
    const [land,setLand] = useState("");
    const [region,setRegion] = useState("");
    const [darstellen,setDarstellen] = useState("");
    const [genre, setGenre] = useState([]);
    const [lineup, setLineup] = useState([]);
    const [minAlter, setMinAlter] = useState("");
    const [muttiZettel, setMZ] = useState(true);
    const [state3, setState3] = useState(true);
    const [state4, setState4] = useState(true);
    const [state5, setState5] = useState(true);
    const [dresscode, setDresscode] = useState(null);
    const [specials, setSpecials] = useState("");
    const [state6, setState6] = useState(true);
    const [state7, setState7] = useState(true);
    const [state8, setState8] = useState(true);
    const [state9, setState9] = useState(true);
    const [ticketLink, setTicketLink] = useState(null);
    const [VVK, setVVK] = useState(null);
    const [free,setFree] = useState(false);
    const [oeffnung, setOeffnung] = useState("");
    const [abendkasse, setAbendkasse] = useState("");
    const [zusatz, setZusatz] = useState("");
    const [MVZ, setMVZ] = useState("");
    const [beschreibung, setBeschreibung] = useState("");
    const [veranstalter, setVeranstalter] = useState();
    const [stickerBooked, setStickerBooked] = useState(false);
    const [storyLink, setStoryLink] = useState('');
    useEffect(() => {
      props.setData({
      images: banner ? [banner] : [],
      name : eventName,
      start: startDate && startTime && new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes()),
      end: state ? startDate && startTime && new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes()) : endDate && endTime && new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes()),
      venue: venue,
      address: location??null,
      city: city??null,
      price: free ? 0 : parseFloat(VVK),
      ticket_link: ticketLink,
      description: beschreibung,
      genre: genre,
      min_age: minAlter,
      dresscode: dresscode,
      organizer: veranstalter ? [veranstalter] : [],
      lineup: lineup,
      story_link: stickerBooked ? storyLink : null,
    })
    },[banner,eventName,startDate,endDate,startTime,endTime,venue,city,location,VVK,beschreibung,free,state,genre,minAlter,dresscode,lineup,veranstalter,stickerBooked,storyLink])

    return (
      <div className="ErstellenInputs">
        <div style={{color: 'white'}}>Erforderliche Angaben</div>
        <div className="InputsItem">
          <TitelbildInput banner={banner} setBanner={setBanner}></TitelbildInput>
        </div>
        <div className="InputsItem" >
          <EventTitelInput onChange={setEventName} eventName={eventName} ></EventTitelInput>
        </div>
        <div className="InputsItem">
          <DatumInput startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} startTime={startTime} setStartTime={setStartTime} endTime={endTime} setEndTime={setEndTime} state={state} setState={setState} ></DatumInput>
        </div>
        <div className="InputsItem">
          <AdresseInput location={location} setVenue={setVenue} setCity={setCity} setLocation={setLocation} state={state2} setState={setState2} land={land} setLand={setLand} region={region} setRegion={setRegion} darstellen={darstellen} setDarstellen={setDarstellen}></AdresseInput>
        </div>
        <div className="InputsItem">
          <GenreInput genre={genre} setGenre={setGenre}></GenreInput>
        </div>
        <div className="InputsItem">
          <LineupInput lineup={lineup} setLineup={setLineup}></LineupInput>
        </div>
        {/*<div className="InputsItem">
          <DetailsInput genre={genre} setGenre={setGenre} minAlter={minAlter} setMinAlter={setMinAlter} muttiZettel={muttiZettel} setMZ={setMZ} dresscode={Dresscode} setDresscode={setDresscode} specials={specials} setSpecials={setSpecials} state={state3} setState={setState3} state2={state4} setState2={setState4} state3={state5} setState3={setState5}></DetailsInput>
        </div>*/}
        <div className="InputsItem">
          <EintrittInput ticketLink={ticketLink} setTicketLink={setTicketLink} VVK={VVK} setVVK={setVVK} free={free} setFree={setFree} oeffnung={oeffnung} setOeffnung={setOeffnung} abendkasse={abendkasse} setAbendkasse={setAbendkasse} zusatz={zusatz} setZusatz={setZusatz} MVZ={MVZ} setMVZ={setMVZ} state={state6} setState={setState6} state2={state7} setState2={setState7} state3={state8} setState3={setState8} state4={state9} setState4={setState9} ></EintrittInput>
        </div>
        <div className="InputsItem">
          <BeschreibungInput beschreibung={beschreibung} setBeschreibung={setBeschreibung}></BeschreibungInput>
        </div>
        {/* <div className="InputsItem">
          <ZusatzInfosInput></ZusatzInfosInput>
          </div>
          <div className="InputsItem">
          <CoVeranstalterInput></CoVeranstalterInput>
        </div> */}
        <div style={{color: 'white'}}>Optionale Angaben</div>
        <div className="InputsItem">
          <MinAlterInput minAlter={minAlter} setMinAlter={setMinAlter} state={state3} setState={setState3}></MinAlterInput>
        </div>
        <div className="InputsItem">
          <DresscodeInput dresscode={dresscode} setDresscode={setDresscode} state={state4} setState={setState4}></DresscodeInput>
        </div>
        <div className="InputsItem">
          <VeranstalterInput veranstalter={veranstalter} setVeranstalter={setVeranstalter}></VeranstalterInput>
        </div>
        {partner === 'koelnisttechno' && <div className="InputsItem">
          <StoryLinkInput eventName={eventName} stickerBooked={stickerBooked} setStickerBooked={setStickerBooked} storyLink={storyLink} setStoryLink={setStoryLink}></StoryLinkInput>
        </div>}
      </div>
    );
  }

  function TitelbildInput(props) {
    function pressUploadBtn() {
      document.getElementById("getFile")?.click();
    }
    useEffect(async () => {
      if(!props.banner) return;
      document.getElementById("output_image").style.visibility='visible';
      document.getElementById('UploadBtn').style.visibility='hidden';
      change_color_icon_Nav("Icon", "InputIcon", 0);
      const downloadUrl = await getDownloadURL(ref(storage, props.banner));
      document.getElementById('output_image').src = downloadUrl;
    }, [props.banner]);
    return (
      <div className="InputBox" id="Titelbild">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput2">
            {titelbildIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Titelbild</div>
            <div className="InputDetails">
              Wähle ein ansprechendes Bild.
            </div>
          </div>
        </div>
        <div class="flex-gap-10">
          <div className="BildBorder" id="BildBorder" style={{maxHeight:"170px",height:"100vh"}}>
            <button
              onClick={pressUploadBtn}
              title="Upload your Image"
              className="UploadBtn"
              id="UploadBtn"
              >
              <div id="upload">Bild Auswählen</div>
              <input
                type={"file"}
                id="getFile"
                className="imgInp"
                accept="image/png ,image/jpeg, image/jpg"
                onChange={(evt, i) => {
                  preview_image_event(evt, i);
                  const file = evt.target.files[0]
                  const reference = ref(storage,"/event_images/temp/" + file.name)
                  uploadBytes(reference,file)
                  .then(() => {
                      console.log(1)
                      props.setBanner(reference.fullPath)
                    })
                    .catch(e => console.log(e))
                }}
                />
            </button>
            <img
              id="output_image"
              alt=" "
              className="output_image_style"
            />
          </div>
          <div>
            <button
              onClick={() => {
                delete_image_event();
                deleteObject(ref(storage,props.banner));
              }}
              id="delete"
              title="Delete your Image"
              style={{position:"unset"}}
              >
              Bild löschen
              <i className="far fa-trash-alt" id="delete-icon"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  function EventTitelInput(props) {
    function checkName(value) {
      if (value.length < 3) {
        //rechange_Text_color("InputName", "iconBeschreibung", 1);
        rechange_color_icon_Nav("Icon", "InputIcon", 1);
      } else {
        //change_Text_color("iconBeschreibung", "InputName", 1);
        change_color_icon_Nav("Icon", "InputIcon", 1);
      }
    }
    useEffect(() => props.eventName && checkName(props.eventName), [props.eventName]);
    return (
      <div className="InputBox" id="Eventname" key={"EventNameDiv"}>
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {eventnameIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Eventname</div>
            <div className="InputDetails">
              Gib den Namen des Event ein.
            </div>
          </div>
        </div>
        <input
          name="Name"
          className="TextInput"
          placeholder="Eventname"
          id="EventNameInput"
          key={"NameInput"}
          value={props.eventName}
          onChange={(e) => {
            props.onChange(e.target.value)
            checkName(e.target.value)
          }}
        ></input>
      </div>
    );
  }

  function DatumInput(props) {
    useEffect(() => {
      console.log(props.startDate)

      let complete = false;
      if(props.state) {
        if(props.startDate && props.startTime) {
          complete = true;
        }
      } else {
        if(props.startDate && props.startTime && props.endDate && props.endTime) {
          complete = true;
        }
      }

      if(complete) {
        change_color_icon_Nav("Icon", "InputIcon", 2);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 2);
      }
    }, [props.startDate, props.startTime, props.endDate, props.endTime, props.state]);

    function change() {
      if (props.state === true) {
        document.getElementById("festesDatum").style.display = "flex";
      } else {
        props.setEndDate(new Date());
        props.setEndTime("");
        document.getElementById("festesDatum").style.display = "none";
      }
    }
    return (
      <div className="InputBox" id="Datum">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {datumIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Datum & Beginn</div>
            <div className="InputDetails">
            Gib an, in welchem Zeitraum Leute zu deinem Event kommen können.{" "}
            </div>
          </div>
        </div>
        <div className="flex-gap-10">
        <DatePicker
          selected={props.startDate}
          onChange={props.setStartDate}
          dateFormat="dd.MM.yyyy"
          className="TextInput"
          style={{ flex: 1 }}
          minDate={new Date()}
          placeholderText="Datum"
          locale="de"
        />
        <DatePicker
          selected={props.startTime}
          onChange={props.setStartTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Uhrzeit"
          dateFormat="HH:mm"
          className="TextInput"
          style={{ flex: 1 }}
          placeholderText="Uhrzeit"
          locale="de"
        />
        </div>
        <div className="flex-gap-10">
          <h3 id="festesEnde">Festes Ende</h3>
          {/* Start of Checkbox */}
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                props.setState(!props.state);
                change();
              }}
            />
            <span className="slider round"></span>
          </label>
          {/* End of Checkbox */}
        </div>
        <div id="festesDatum" className="flex-gap-10">
          <DatePicker
            selected={props.endDate}
            onChange={props.setEndDate}
            dateFormat="dd.MM.yyyy"
            className="TextInput"
            style={{ flex: 1 }}
            minDate={new Date() > props.startDate??0 ? new Date() : props.startDate}
            placeholderText="Datum"
            locale="de"
          />
          <DatePicker
            selected={props.endTime}
            onChange={props.setEndTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Uhrzeit"
            dateFormat="HH:mm"
            className="TextInput"
            style={{ flex: 1 }}
            placeholderText="Uhrzeit"
            locale="de"
          />
        </div>
      </div>
    );
  }

  function AdresseInput(props) {
    useEffect(() => {
      if(props.location || props.venue) {
        change_color_icon_Nav("Icon", "InputIcon", 3);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 3);
      }
    }, [props.location, props.venue]);

    return (
      <div className="InputBox" id="Adresse">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {adresseIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Location</div>
            <div className="InputDetails">
              Suche nach der Location oder gib die Adresse an
            </div>
          </div>
        </div>
        <LocationInput
          className="TextInput"
          placeholder="Adresse/Location eingeben"
          address={props.location}
          city={props.city}
          venue={props.venue}
          setAddress={(e) => {
            props.setLocation(e);
            // console.log(location);
          }}
          setCity={(e) => {
            props.setCity(e);
            // console.log(e)
          }}
          setVenue={(e) => {
            props.setVenue(e);
            // console.log(e)
          }}
        ></LocationInput>
      </div>
    );
  }

  function GenreInput(props) {
    useEffect(() => {
      console.log(props.genre)
      if(props.genre?.length > 0) {
        change_color_icon_Nav("Icon", "InputIcon", 4);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 4);
      }
    }, [props.genre]);

    const [genreList, setGenreList] = useState([]);
    const [idk, setIdk] = useState("");

    useEffect(() => getDocs(collection(firestore, 'genres')).then(e => setGenreList(e.docs)), []);

    return (
      <div className="InputBox" id="Genre">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {genreIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Musikgenre</div>
            <div className="InputDetails">
              Wähle gerne mehrere Genres aus.{" "}
            </div>
          </div>
        </div>
        <select className="TextInput" onChange={(e) => {!props.genre.includes(e.currentTarget.value) && props.setGenre(props.genre.concat([e.currentTarget.value])); e.currentTarget.value = 'null'}}>
          <option value="null">Bitte auswählen</option>
          {genreList.map(e => <option value={e.id}>
            {e.data().de}
          </option>)}
        </select>
        <div>
          <p id="ausgewaehlteGenres">Ausgewählte Genre:</p>
          <div className="GenreBox">
            {props.genre.map((item) => (
              <div
                id="GenreBoxItem"
                onClick={() => {
                  var index = props.genre?.indexOf(item);
                  if (index > -1) {
                    let tmp = props.genre;
                    tmp.splice(index, 1);
                    props.setGenre(tmp);
                    if(tmp.length === 0) rechange_color_icon_Nav("Icon", "InputIcon", 4);
                    setIdk(!idk);
                  }
                }}
                className="genreButton"
              >
                <p className="ItemText">{genreList.find(e => e.id === item)?.data().de}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function LineupInput(props) {
    useEffect(() => {
      if(props.lineup?.length > 0) {
        change_color_icon_Nav("Icon", "InputIcon", 5);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 5);
      }
    }, [props.lineup]);

    const [idk, setIdk] = useState("");

    const [artistList, setArtistList] = useState([]);
    useEffect(() => props.lineup && Promise.all(Array.from(props.lineup).map(e => e.startsWith('$') ? { id: e, data: () => ({ name: e.substring(1)})} : getDoc(doc(collection(firestore, 'artists'), e)))).then(e => setArtistList(e)), [props.lineup]);

    return (
      <div className="InputBox" id="Lineup">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {lineupIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Lineup</div>
            <div className="InputDetails">
              Wähle bitte alle Künstler aus, die auftreten.{" "}
            </div>
          </div>
        </div>
        <ArtistInput
          key={props.lineup}
          className="TextInput"
          placeholder="Artist hinzufügen..."
          onSelect={e => !props.lineup.includes(e) && props.setLineup(props.lineup.concat([e]))}
        ></ArtistInput>
        <div>
          <p id="ausgewaehlteGenres">Ausgewählte Künstler:</p>
          <div className="GenreBox">
            {props.lineup?.map((item) => (
              <div
                id="GenreBoxItem"
                onClick={() => {
                  var index = props.lineup?.indexOf(item);
                  if (index > -1) {
                    let tmp = props.lineup;
                    tmp.splice(index, 1);
                    props.setLineup(tmp);
                    if(tmp.length === 0) rechange_color_icon_Nav("Icon", "InputIcon", 5);
                    setIdk(!idk);
                  }
                }}
                className="genreButton"
              >
                <p className="ItemText">{artistList.find(e => e.id === item)?.data()?.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }


  // States: 3,4,5
  function DetailsInput(props) {
    const [activeEle, setActiveEle] = useState("");
    const [idk, setIdk] = useState(true);
    const [currGenre, setCurrGenre] = useState("");
    const [genreDone,setGenreDone] = useState(false);
    const [minAlterDone,setMinAlterDone] = useState(false);
    const [dresscodeDone,setDresscodeDone] = useState(true);
    const [specialsDone,setSpecialsDone] = useState(true);
    function changeAlterDiv() {
      if (!props.state) {
        document.getElementById("MinAlter").style.height = "28.272251308900522vh";
        document.getElementById("minAlterInp").style.visibility = "hidden";
       
        // TODO: Add Element for every item in genre
      } else {
        document.getElementById("minAlterInp").style.visibility = "visible";
        document.getElementById("minAlterInp").click();
       
      }
    }

    function changeDressDiv() {
      if (!props.state2) {
        document.getElementById("Dresscode").style.height = "8.37696335078534vh";
        document.getElementById("DresscodeInput").style.visibility = "hidden";
      } else {
        document.getElementById("Dresscode").style.height = "20.94240837696335vh";
        document.getElementById("DresscodeInput").style.visibility = "visible";
      }
    }

    function changeSpecialsDiv() {
      if (props.state3 !== true) {
        document.getElementById("Getraenke").style.height = "8.37696335078534vh";
        document.getElementById("SpecialsInput").style.visibility = "hidden";
        setSpecialsDone(true)
        console.log(props.specials)
        
      } else {
        document.getElementById("Getraenke").style.height = "20.94240837696335vh";
        document.getElementById("SpecialsInput").style.visibility = "visible";
        setSpecialsDone(false)
        console.log(props.specials)
      }
    }

    function checkKey(e) {
      if (e.keyCode === 13 && props.genre?.length <= 3) {
        props.genre.push(currGenre);
        setCurrGenre("");
      } else if (e.keyCode === 13 && props.genre?.length === 4) {
        alert("Sie können nur 4 Genres Hinzufügen!");
        setCurrGenre("");
      }
    }
    function changeColor(id) {
      if (activeEle !== "") {
        document.getElementById(activeEle).style.backgroundColor = "#2c2c2c";
      }
      setActiveEle(id);
      document.getElementById(id).style.backgroundColor = "#0D6EFD";
      if (activeEle === id) {
        document.getElementById(activeEle).style.backgroundColor = "#2c2c2c";
        setActiveEle("");
      }
    }

    function checkSpecials(value){
      if(props.state3 === false && value?.length < 3 ){
        console.log("Less then 3 Chars")
      }
      else{
        console.log("More then 3 Chars")
        // console.log()
      }
    }

    function checkDresscode(value){
      if (props.state2 === true && value?.length < 3){
        console.log("Not Empty")
      }
      else{
        console.log("Empty")
      }
    }
    return (
      <div className="InputBox" id="Details">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {detailsIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Details</div>
            <div className="InputDetails">
              Gib verschiedene Details an um dein Event möglichst genau zu beschreiben.
            </div>
          </div>
        </div>

        {/* Start of UnterInputs */}

        <div className="DetailsInputBox">
          {/* Start of Genre Input */}
          <div className="DetailsInputBoxItem" id="Genre">
            <h4 className="InputName Unter">Musikgenre</h4>
            <h4 className="InputDetails UnterDet">
              Wähle gerne mehrere Genres aus.
            </h4>
            <div className="unterIcon InInput">{genreIcon}</div>
            <input
              className="TextInput"
              placeholder="z.B. Techno …"
              id="GenreInput"
              onKeyDown={(e) => checkKey(e)}
              value={currGenre}
              onClick={() => {}}
              onChange={(e) => {
                setCurrGenre(e.target.value);
              }}
            ></input>
            <GenreList genre={props.genre}></GenreList>
          </div>
          {/* End of Genre Input */}

          {/* Start of  MinAlter Input */}
          <div className="DetailsInputBoxItem" id="MinAlter">
            <h4 className="InputName Unter">Mindestalter</h4>
            <h4 className="InputDetails UnterDet">
              Wähle das Mindestalter aus.
            </h4>
            <div className="unterIcon InInput">{minAlterIcon}</div>
            <div className="selectMinAlter">
              <div
                className="selectMinAlterItem"
                onClick={() => {
                  props.setMinAlter("16 Jahre");
                  changeColor("16");
                }}
                id="16"
              >
                <p id="alterNum">16 Jahre</p>
              </div>
              <div
                className="selectMinAlterItem"
                onClick={() => {
                  props.setMinAlter("18 Jahre");
                  changeColor("18");
                }}
                id="18"
              >
                <p id="alterNum">18 Jahre</p>
              </div>
              <div
                className="selectMinAlterItem"
                onClick={() => {
                  props.setMinAlter("21 Jahre");
                  changeColor("21");
                }}
                id="21"
              >
                <p id="alterNum">21 Jahre</p>
              </div>
              <div
                className="selectMinAlterItem"
                style={{ top: "6.282722513089006vh", left: "-9.895833333333334vw" }}
                id="25"
                onClick={() => {
                  props.setMinAlter("25 Jahre");
                  changeColor("25");
                }}
              >
                <p id="alterNum">25 Jahre</p>
              </div>
              <div
                className="selectMinAlterItem"
                style={{ width: "13.020833333333334vw", top: "6.282722513089006vh", left: "-9.635416666666666vw" }}
                id="custom"
                onClick={() => {
                  props.setState(!props.state);
                  changeAlterDiv();
                  props.setMinAlter("custom");
                  changeColor("custom");
                }}
              >
                <p id="alterNum">Tippe ein anderes Alter ein</p>
              </div>
              <input type="text" id="minAlterInp" maxLength={2}></input>
              <div>
                <h3 id="mutti-zettel">Muttizettel erlaubt?</h3>
                {/* Start of Checkbox */}
                <label
                  className="switch"
                  style={{ left: "-26.041666666666668vw", top: "7.06282722513089vh" }}
                >
                  <input
                    type="checkbox"
                    onClick={() => {
                      props.setMZ(!props.muttiZettel);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
                {/* End of Checkbox */}
              </div>
            </div>
          </div>
          {/* End of MinAlter Input */}

          {/* Start of Dresscode Input */}
          <div className="DetailsInputBoxItem" id="Dresscode">
            <h4 className="InputName Unter">Dresscode</h4>
            <h4 className="InputDetails UnterDet">
              Definiere den genauen Dresscode. Gibt es keinen lass das Feld
              frei.
            </h4>
            <div className="unterIcon InInput">{dresscodeIcon}</div>
            {/* Start of Checkbox */}
            <label className="switch" style={{ left: "29.6875vw", top: "-6.806282722513089vh" }}>
              <input
                type="checkbox"
                onClick={() => {
                  props.setState2(!props.state2);
                  changeDressDiv();
                }}
              />
              <span className="slider round"></span>
            </label>
            {/* End of Checkbox */}
            <input
              className="TextInput"
              placeholder="z.B. Nur weiße T-Shirts ..."
              id="DresscodeInput"
              value={props.dresscode}
              onClick={() => {}}
              onChange={(e) => props.setDresscode(e.target.value)}
            ></input>
          </div>
          {/* End of Dresscode Input*/}

          {/* Start of Getraenke Input*/}
          <div className="DetailsInputBoxItem" id="Getraenke">
            <h4 className="InputName Unter">Getränkespecials</h4>
            <h4 className="InputDetails UnterDet">
              Was wird es bei dem Event für Specials geben?
            </h4>
            <div className="unterIcon InInput">{getraenkeIcon}</div>
            {/* Start of Checkbox */}
            <label className="switch" style={{ left: "29.6875vw", top: "-6.806282722513089vh" }}>
              <input
                type="checkbox"
                onClick={() => {
                  props.setState3(!props.state3);
                  changeSpecialsDiv();
                }}
              />
              <span className="slider round"></span>
            </label>
            {/* End of Checkbox */}

            <input
              className="TextInput"
              placeholder="z.B. Mochito den ganzen Abend für 2€ ..."
              id="SpecialsInput"
              value={props.specials}
              onClick={() => {}}
              onChange={(e) => {props.setSpecials(e.target.value);checkSpecials(props.specials)}}
            ></input>
          </div>
          {/* End of Getraenke Input*/}

          {/* End of UnterInputs  */}
        </div>
      </div>
    );
    function GenreList(props) {
      return (
        <div>
          <h1 id="ausgewaehlteGenres">Ausgewählte Genres (max. 4):</h1>
          <div className="GenreBox">
            {props.genre.map((item) => (
              <div
                id="GenreBoxItem"
                onClick={() => {
                  var index = props.genre?.indexOf(item);
                  if (index > -1) {
                    props.genre.splice(index, 1);
                    setIdk(!idk);
                    setCurrGenre("");
                  }
                }}
                className="genreButton"
              >
                <h3 className="ItemText">{item}</h3>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }



  // States 6,7,8,9
  function EintrittInput(props) {
    useEffect(() => {
      if(props.VVK || props.VVK === 0) {
        change_color_icon_Nav("Icon", "InputIcon", 6);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 6);
      }

      if(props.VVK === 0) {
        document.getElementById("freierEintrittBtn").style.backgroundColor =
        "white";
        document.getElementById("freierEintrittBtn").style.color = "black";

      } else {
        document.getElementById("freierEintrittBtn").style.backgroundColor =
        "#0d6efd";
        document.getElementById("freierEintrittBtn").style.color = "white";
      }
    }, [props.VVK]);

    const [vvkDone,setVvkDone] = useState(false);
    const [akDone,setAkDone] = useState(false);
    const [zusatzDone,setZusatzDone] = useState(false);
    const [mvzDone,setMvzDone] = useState(false);
    function changeZusatz() {
      if (!props.state3) {
        document.getElementById("Zusatz").style.visibility = "hidden";
        setZusatzDone(false)
      } else {
        document.getElementById("Zusatz").style.visibility = "visible";
        setZusatzDone(true);
      }
    }

    function changeMVZ() {
      if (!props.state4) {
        document.getElementById("MVZ").style.visibility = "hidden";
        document.getElementById("euroIcon3").style.visibility = "hidden";
        setMvzDone(false)
      } else {
        document.getElementById("MVZ").style.visibility = "visible";
        document.getElementById("euroIcon3").style.visibility = "visible";
        setMvzDone(true)
      }
    }
    
    function changeAbendKasse() {
      if (!props.state2) {
        document.getElementById("abendkasseDetails").style.visibility =
        "hidden";
        document.getElementById("OF").style.visibility = "hidden";
        document.getElementById("AK").style.visibility = "hidden";
        document.getElementById("oeffnungsZeit").style.visibility = "hidden";
        document.getElementById("abendkassePreis").style.visibility = "hidden";
        document.getElementById("euroIcon2").style.visibility = "hidden";
        setAkDone(false)
      } else {
        document.getElementById("abendkasseDetails").style.visibility =
        "visible";
        document.getElementById("AK").style.visibility = "visible";
        document.getElementById("OF").style.visibility = "visible";
        document.getElementById("oeffnungsZeit").style.visibility = "visible";
        document.getElementById("abendkassePreis").style.visibility = "visible";
        document.getElementById("euroIcon2").style.visibility = "visible";
        setAkDone(true)
      }
    }
    function change() {
      if (!props.state) {
        props.setFree(false);
        document.getElementById("freierEintrittBtn").style.backgroundColor =
        "white";
        document.getElementById("freierEintrittBtn").style.color = "black";
        setVvkDone(false);
        document.getElementById('VVK-Preis').style.visibility="visible";
        document.getElementById('euroIcon').style.visibility="visible";
        document.getElementById('oder').style.visibility="visible";
        document.getElementById('InputTitel').style.visibility="visible";

      } else {
        props.setFree(true);
        document.getElementById("freierEintrittBtn").style.backgroundColor =
        "#0d6efd";
        document.getElementById("freierEintrittBtn").style.color = "white";
        setVvkDone(true);
        props.setVVK("");
        document.getElementById('VVK-Preis').style.visibility="hidden";
        document.getElementById('euroIcon').style.visibility="hidden";
        document.getElementById('oder').style.visibility="hidden";
        document.getElementById('InputTitel').style.visibility="hidden";
      }
    }
    function checkVVK(value){
      if (value?.length < 3){
          setVvkDone(false)
      }
      else {
          setVvkDone(true);
      }
    }

    function checkInp(){
      if (vvkDone === true && akDone === true && zusatzDone === true && mvzDone === true){
        console.log(1)
      }
    }
    return (
      <div className="InputBox" id="Eintritt">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {eintrittIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Eintritt</div>
            <div className="InputDetails">
            Gib den Ticketpreis sowie weitere Informationen rund um den Eintritt an.
            </div>
          </div>
        </div>
        <div className="input-top-label">
          Ticketpreis
        </div>
        <div className="flex-gap-10">
          <input
            type={"number"}
            className="TextInput"
            id="VVK-Preis"
            value={props.VVK??''}
            onChange={(e) => {props.setVVK(e.target.value);checkVVK(e.target.value)}}
            onBlur={checkInp()}
            min={0}
          ></input>
          <div style={{color: 'white'}}>€&nbsp;oder</div>
          <div
            id="freierEintrittBtn"
            onClick={() => {
              props.setVVK(props.VVK === 0 ? null : 0);
            }}
          >
            Freier&nbsp;Eintritt
          </div>
        </div>
         {/*<div className="TextInput" style={{marginTop:"20px",height:"45px",display:"flex",alignItems:"center"}}>
           <h1 style={{fontSize:"0.9vw",width:"80%"}}>
           Kein VVK, nur Abendkasse
           </h1>
             {/* Start of Checkbox }
            <label className="switch" style={{left:"4vw"}}>
              <input
                type="checkbox"
                onClick={() => {
                  props.setAK(!props.AK);
                }}
              />
              <span className="slider round" ></span>
            </label>
            {/* End of Checkbox }
            </div>*/}
            <div className="input-top-label">
          Ticketstore-Link (optional)
        </div>
        <input
          type={"url"}
          className="TextInput"
          id="TicketLink"
          placeholder="Ticketstore-Link (optional)"
          value={props.ticketLink}
          onChange={(e) => props.setTicketLink(e.target.value)}
        ></input>
      </div>
    );
  }

  function BeschreibungInput(props) {
    function check(value) {
      if (value.length < 3) {
        //rechange_Text_color("InputName", "iconBeschreibung", 10);
        rechange_color_icon_Nav("Icon", "InputIcon", 7);
      } else {
        //change_Text_color("iconBeschreibung", "InputName", 10);
        change_color_icon_Nav("Icon", "InputIcon", 7);
      }
    }
    useEffect(() => props.beschreibung && check(props.beschreibung), [props.beschreibung]);
    return (
      <div className="InputBox" id="Beschreibung">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {beschreibungIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Beschreibung</div>
            <div className="InputDetails">
              Schreibe Interessenten, was sie erwarten wird.
            </div>
          </div>
        </div>
        <textarea
          draggable="false"
          id="beschreibung"
          value={props.beschreibung}
          onChange={(e) => {props.setBeschreibung(e.target.value);check(e.target.value)}}
          onBlur={() => {}}
          style={{resize:"none"}}
        ></textarea>
      </div>
    );
  }

  function StoryLinkInput(props) {
    function check(value) {
      if (value?.length < 3) {
        //rechange_Text_color("InputName", "iconBeschreibung", 10);
        rechange_color_icon_Nav("Icon", "InputIcon", 11);
      } else {
        //change_Text_color("iconBeschreibung", "InputName", 10);
        change_color_icon_Nav("Icon", "InputIcon", 11);
      }
    }
    useEffect(() => check(props.storyLink), [props.storyLink]);

    return (
      <div className="InputBox" id="StoryLink">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {storyLinkIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Link Sticker auf der Story</div>
            <div className="InputDetails">
              Für eine kleine Bearbeitungsgebühr von 20,00EUR fügen wir einen Link auf deiner Story ein<br />
              (z.B. Link zum Ticketshop oder Facebookevent)
            </div>
          </div>
        </div>
        <div className="flex-gap-10">
          <div style={{color: 'white', fontSize: '110%'}}>Mit Link Sticker</div>
          {/* Start of Checkbox */}
          <label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                props.setStickerBooked(!props.stickerBooked);
              }}
              value={props.stickerBooked}
            />
            <span className="slider round"></span>
          </label>
          {/* End of Checkbox */}
        </div>
        {props.stickerBooked && <>
          <div className="input-top-label">
            Füge deinen Link hier ein:
          </div>
          <input
            type={"url"}
            className="TextInput"
            id="TicketLink"
            placeholder="Link einfügen"
            value={props.storyLink}
            onChange={(e) => props.setStoryLink(e.target.value)}
          ></input>
          <div style={{color: 'white', fontSize: '90%', marginTop: 10}}>
            Bitte schicke uns die Bearbeitungsgebühr in Höhe von 20,00EUR unverzüglich per PayPal an <b>koelnisttechno.official@gmail.com</b> mit dem Hinweis "{props.eventName || '[EVENTNAME]'} Story-Link"
          </div>
        </>}
      </div>
    );
  }

  function MinAlterInput(props) {
    useEffect(() => {
      if(props.minAlter) {
        change_color_icon_Nav("Icon", "InputIcon", 8);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 8);
      }
    }, [props.minAlter]);

    const [activeEle, setActiveEle] = useState("");
    
    function changeColor(id) {
      if (activeEle !== "") {
        document.getElementById(activeEle).style.backgroundColor = "#2c2c2c";
      }
      setActiveEle(id);
      document.getElementById(id).style.backgroundColor = "#0D6EFD";
      if (activeEle === id) {
        props.setMinAlter(null);
        document.getElementById(activeEle).style.backgroundColor = "#2c2c2c";
        setActiveEle("");
      }
    }

    function changeAlterDiv(show) {
      if (!show) {
        document.getElementById("minAlterInp").style.visibility = "hidden";
        // TODO: Add Element for every item in genre
      } else {
        document.getElementById("minAlterInp").style.visibility = "visible";
        //document.getElementById("minAlterInp").click();
       
      }
    }

    return (
      <div className="InputBox" id="MinAlter">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {minAlterIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Mindestalter</div>
            <div className="InputDetails">
              Wähle das Mindestalter aus.
            </div>
          </div>
        </div>
        <div className="selectMinAlter">
          <div
            className="selectMinAlterItem"
            onClick={() => {
              changeAlterDiv(false);
              props.setMinAlter(16);
              changeColor("16");
            }}
            id="16"
          >
            <p id="alterNum">16 Jahre</p>
          </div>
          <div
            className="selectMinAlterItem"
            onClick={() => {
              changeAlterDiv(false);
              props.setMinAlter(18);
              changeColor("18");
            }}
            id="18"
          >
            <p id="alterNum">18 Jahre</p>
          </div>
          <div
            className="selectMinAlterItem"
            onClick={() => {
              changeAlterDiv(false);
              props.setMinAlter(21);
              changeColor("21");
            }}
            id="21"
          >
            <p id="alterNum">21 Jahre</p>
          </div>
          <div
            className="selectMinAlterItem"
            style={{ top: "6.282722513089006vh", left: "-9.895833333333334vw" }}
            id="25"
            onClick={() => {
              changeAlterDiv(false);
              props.setMinAlter(25);
              changeColor("25");
            }}
          >
            <p id="alterNum">25 Jahre</p>
          </div>
          <div
            className="selectMinAlterItem"
            id="custom"
            onClick={() => {
              changeAlterDiv(activeEle === "custom" ? false : true);
              props.setMinAlter(null);
              changeColor("custom");
            }}
          >
            <p id="alterNum">Anderes</p>
          </div>
          <input type="number" id="minAlterInp" maxLength={2} onChange={e => props.setMinAlter(parseInt(e.target.value))}></input>
        </div>
      </div>
    );
  }

  function DresscodeInput(props) {
    const noDresscode = 'Come as you are';

    useEffect(() => {
      if(props.dresscode?.length >= 3) {
        change_color_icon_Nav("Icon", "InputIcon", 9);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 9);
      }

      if(props.dresscode === noDresscode) {
        document.getElementById("noDresscodeBtn").style.backgroundColor =
        "white";
        document.getElementById("noDresscodeBtn").style.color = "black";

      } else {
        document.getElementById("noDresscodeBtn").style.backgroundColor =
        "#0d6efd";
        document.getElementById("noDresscodeBtn").style.color = "white";
      }
    }, [props.dresscode]);

    function changeDressDiv() {
      if (!props.state) {
        props.setDresscode(null);
        document.getElementById("DresscodeInput").style.visibility = "hidden";
      } else {
        document.getElementById("DresscodeInput").style.visibility = "visible";
      }
    }

    return (
      <div className="InputBox" id="Dresscode">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {dresscodeIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">Dresscode</div>
            <div className="InputDetails">
              Definiere den genauen Dresscode.
            </div>
          </div>
        </div>
        <div className="flex-gap-10">
          {/*<label className="switch">
            <input
              type="checkbox"
              onClick={() => {
                props.setState(!props.state);
                changeDressDiv();
              }}
            />
            <span className="slider round"></span>
          </label>*/}
          <input
            className="TextInput"
            placeholder="z.B. Nur weiße T-Shirts ..."
            value={props.dresscode??''}
            onClick={() => {}}
            onChange={(e) => props.setDresscode(e.target.value?.length === 0 ? null : e.target.value)}
          ></input>
          <div style={{color: 'white'}}>oder</div>
          <div
            id="noDresscodeBtn"
            onClick={() => {
              props.setDresscode(props.dresscode === noDresscode ? null : noDresscode);
            }}
          >
            Kein&nbsp;Dresscode
          </div>
        </div>
      </div>
    );
  }

  function VeranstalterInput(props) {
    useEffect(() => {
      if(props.veranstalter) {
        change_color_icon_Nav("Icon", "InputIcon", 10);
      } else {
        rechange_color_icon_Nav("Icon", "InputIcon", 10);
      }
    }, [props.veranstalter]);

    return (
      <div className="InputBox" id="Veranstalter">
        <div className="box-title">
          <div className="InputIcon" id="TitelbildIconInput">
            {coVeranstalterIcon}
          </div>
          <div style={{marginLeft: 10}}>
            <div className="InputName">(Co-)Veranstalter</div>
            <div className="InputDetails">
              Gib an, wer dieses Event (mit) veranstaltet.
            </div>
          </div>
        </div>
        <OrganizerInput
          className="TextInput"
          placeholder="Veranstalternamen eingeben"
          organizer={props.veranstalter}
          setOrganizer={props.setVeranstalter}
        ></OrganizerInput>
      </div>
    );
  }

  function ZusatzInfosInput() {
    return (
      <div className="InputBox" id="ZstInfos">
        <h4 className="InputName">Zusatzinfos Adresse</h4>
        <h4 className="InputDetails">
          Gib den Ticketpreis sowie weitere Informationen an um mehr Leute zu
          deinem Event zu holen.
        </h4>
        <div className="InputIcon">{zstAdresseIcon}</div>
      </div>
    );
  }

  function CoVeranstalterInput() {
    return (
      <div className="InputBox" id="CoVeranstalter">
        <h4 className="InputName">Co-Veranstalter</h4>
        <h4 className="InputDetails">
          Gibt es andere Veranstalter mit denen du dieses Event zusammen
          veranstaltest? Schicke ihnen eine Einladung.
        </h4>
        <div className="InputIcon">{coVeranstalterIcon}</div>
      </div>
    );
  }

  function SideBar() {
    const location = useLocation();
    const [partner, setPartner] = useState(null);
    useEffect(() => setPartner(queryString.parse(location.search).partner), [location.search]);

    const [open, setOpen] = useState(false);

    function smoothScroll(divid) {
      document.getElementById(divid).scrollIntoView({ behavior: "smooth" });
    }
    return (
      <div className="SidebarEventErstellen">
        <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'pointer'}} onClick={() => setOpen(!open)}>
          <div style={{color: 'white', marginBottom: 10, fontFamily: 'Montserrat_SemiBold', fontWeight: 'lighter'}}>Angabenübersicht</div>
          <div><i className={open ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}></i></div>
        </div>
        <div style={{display: open ? 'block' : 'none'}}>
          <div style={{color: 'white', marginTop: 10}}>Erforderliche Angaben</div>
          <div className="itemSideBar" onClick={() => smoothScroll("Titelbild")}>
            <div className="Icon" id="TitelbildIconNav">
              {titelbildIcon}
            </div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Titelbild
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Eventname")}>
            <div className="Icon" id="">
              {eventnameIcon}
            </div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Eventname
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Datum")}>
            <div className="Icon">{datumIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Datum & Beginn
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Adresse")}>
            <div className="Icon">{adresseIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Adresse
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Genre")}>
            <div className="Icon">{genreIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Musikgenre
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Lineup")}>
            <div className="Icon">{lineupIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Lineup
            </h5>
          </div>
          <div className="itemSideBar" onClick={() => smoothScroll("Eintritt")}>
            <div className="Icon">{eintrittIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Eintritt
            </h5>
          </div>
          <div
            className="itemSideBar"
            onClick={() => smoothScroll("Beschreibung")}
          >
            <div className="Icon">{beschreibungIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Beschreibung
            </h5>
          </div>
          {/* <div className="itemSideBar" onClick={() => smoothScroll("ZstInfos")}>
          <div className="Icon">{zstAdresseIcon}</div>
          <h5 id="iconBeschreibung"className="iconBeschreibung">Zst. Adresse</h5>
        </div>
        <div className="itemSideBar" onClick={() => smoothScroll("CoVeranstalter")}>
          <div className="Icon">{coVeranstalterIcon}</div>
          <h5 id="iconBeschreibung"className="iconBeschreibung">Co-Veranstalter</h5>
        </div> */}
          <div style={{color: 'white', marginTop: 10}}>Optionale Angaben</div>
          <div
            className="itemSideBar"
            onClick={() => smoothScroll("MinAlter")}
          >
            <div className="Icon">{minAlterIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Mindestalter
            </h5>
          </div>
          <div
            className="itemSideBar"
            onClick={() => smoothScroll("Dresscode")}
          >
            <div className="Icon">{dresscodeIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Dresscode
            </h5>
          </div>
          <div
            className="itemSideBar"
            onClick={() => smoothScroll("Veranstalter")}
          >
            <div className="Icon">{coVeranstalterIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Veranstalter
            </h5>
          </div>
          {partner === 'koelnisttechno' && <div
            className="itemSideBar"
            onClick={() => smoothScroll("StoryLink")}
          >
            <div className="Icon">{storyLinkIcon}</div>
            <h5 id="iconBeschreibung" className="iconBeschreibung">
              Link Sticker auf der Story
            </h5>
          </div>}
        </div>
      </div>
    );
  }

  function EventErstellenContent(props) {
    const location = useLocation();
    const [partner, setPartner] = useState(null);
    useEffect(() => setPartner(queryString.parse(location.search).partner), [location.search]);

    const [ready, setReady] = useState(false);
    const [data,setData] = useState({});

    useEffect(() => {
      let ready = true;
      if(data.images?.length === 0) {
        ready = false;
      } else if((data.name?.length??0) < 3) {
        ready = false;
      } else if(!data.start || !data.end) {
        ready = false;
      } else if(!data.address && !data.venue) {
        ready = false;
      } else if((data.genre?.length??0) === 0) {
        ready = false;
      } else if(!data.price && data.price !== 0) {
        ready = false;
      } else if((data.description?.length??0) < 3) {
        ready = false;
      }
      setReady(ready);
    }, [data]);

    var uploading = false;
    const submit = async () => {
      if(uploading) return;
      uploading = true;
      document.getElementById('please_wait').style.display = 'flex';
      try {
        console.log(data);
        await Promise.all(data.lineup?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'artists'), {
          name: e.substring(1),
          agent: 'event-form',
          visibility: 'public',
        }).then(x => data.lineup[data.lineup.indexOf(e)] = x.id))??[]);
        await Promise.all(data.organizer?.map(async e => e.startsWith('$') && await addDoc(collection(firestore, 'organizers'), {
          name: e.substring(1),
          agent: 'event-form',
          visibility: 'public',
        }).then(x => data.organizer[data.organizer.indexOf(e)] = x.id))??[]);
        await addDoc(collection(firestore, 'events'), {
          ...data,
          ...(partner ? {partner} : {}),
          agent: 'event-form',
          visibility: 'private',
          pending_review: true,
          type: [],
        })
        .then((_) => {
          alert('Vielen Dank für die Einreichung. Wir prüfen das Event so schnell wie möglich.');
          window.location.href = partner === 'koelinisttechno' ? 'https://koelnisttechno.de' : 'https://elgio.de';
        })
        .catch((e) => {
          alert('Etwas hat nicht geklappt: ' + e.toString());
          uploading = false;
          document.getElementById('please_wait').style.display = 'none';
        });
      } catch(e) {
        alert('Etwas hat nicht geklappt: ' + e.toString());
        uploading = false;
        document.getElementById('please_wait').style.display = 'none';
      }
    };
   
    return (
      <div className="content" style={{height: '100%', overflowY: 'scroll'}}>
        <div style={{padding: 20}}>
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 30}}>
            {partner === 'koelnisttechno' ? <img style={{height: 30, width: 30}} src={kitLogo} /> : <div />}
            <img style={{height: 30, width: 30}} src={logo} />
          </div>
          <div style={{color: 'white', fontFamily: 'Montserrat_Semibold', fontSize: 30}}>Event-Formular</div>
          <div style={{color: '#929292', marginBottom: 30}}>powered by ELGIO</div>
          <SideBar></SideBar>
          <Inputs setData={setData}></Inputs>
          {ready
            ? <div style={{color: 'white'}}>Du hast alle erforderlichen Angaben gemacht</div>
            : <div style={{color: 'white'}}>Du hast noch nicht alle erforderlichen Angaben gemacht</div>
          }
          <button onClick={submit} className="submit-button" disabled={!ready}>Event abschicken</button>
          <div style={{color: '#929292', fontSize: '85%'}}>Mit dem Klicken auf "Event abschicken" stimmst du zu, dass alle angegebenen Daten und Medien zur Weiterverarbeitung und Veröffentlichung auf Apps, Webseiten, Sozialen Medien und weiteren Kanälen von der Elgio UG (haftungsbeschränkt), sowie ihren Partnern, genutzt werden darf.</div>
          {/*ready && <div style={{color: '#929292', fontSize: '85%'}}>Dieses Formular wurde von ELGIO bereitgestellt. Durch die Erstellung stimmst du zu, dass dieses Event auch in der ELGIO App erstellt wird.</div>*/}
        </div>
      </div>
    );
  }


export default EventErstellen;