import './App.css';
import './dashboard.css';
import EventErstellen from './EventErstellen';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div style={{position: 'relative', height: '100%'}}>
        <div className="app" style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
          <EventErstellen />
        </div>
        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1, display: 'none', alignItems: 'center', justifyContent: 'center'}}>
          <div id='please_wait' style={{backgroundColor: '#2c2c2c', color: 'white', borderRadius: 7, padding: 20}}>
            Bitte warten...
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
