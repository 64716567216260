import { useState } from "react";

export function change_color(divid,divid2){
        document.getElementById(divid).style.backgroundColor='#23CB70';
        document.getElementById(divid2).style.backgroundColor='#23CB70';
}

function showText(){
    if(document.getElementById('titelbild-icon-nav').style.backgroundColor=='#23CB70' && document.getElementById('name-icon-nav').style.backgroundColor=='#23CB70' && document.getElementById('land-region-icon-nav').style.backgroundColor=='#23CB70' && document.getElementById('social-media-icon-nav').style.backgroundColor=='#23CB70' && document.getElementById('beschreibung-icon-nav').style.backgroundColor=='#23CB70' && document.getElementById('kontaktdaten-icon-nav').style.backgroundColor=='#23CB70' ){
        document.getElementById('last-text').style.visibility='visible';
    }
}


export function change_Text_color(textId,textId2,index){
    document.getElementsByClassName(textId)[index].style.color="#23CB70";
    document.getElementsByClassName(textId2)[index].style.color="#23CB70";
}

export function rechange_Text_color(textId,textId2,index){
    document.getElementsByClassName(textId)[index].style.color="white";
    document.getElementsByClassName(textId2)[index].style.color="white";
}


export function change_color_icon_Nav(icon,icon2,index){
    let element = document.getElementsByClassName(icon)[index];
    let element2 = document.getElementsByClassName(icon2)[index];
    element.style.backgroundColor="#23CB70"
    element2.style.backgroundColor="#23CB70"    
}

export function rechange_color_icon_Nav(icon,icon2,index){
    let element = document.getElementsByClassName(icon)[index];
    let element2 = document.getElementsByClassName(icon2)[index];
    element.style.backgroundColor="#686767";
    element2.style.backgroundColor="#686767";
}

export function rechange_color(divid,divid2){
        document.getElementById(divid).style.backgroundColor='#686767';
        document.getElementById(divid2).style.backgroundColor='#686767';
}

export function delete_logo(){
    document.getElementById('logoImg').style.visibility="hidden";
    document.getElementsByClassName('LogoUploadBtn')[0].style.visibility="visible";
    document.getElementsByClassName('LogoUploadBtn')[0].style.top="-50%";

}

export function delete_image(){
    document.getElementById("output_image").style.visibility='hidden';
    document.getElementsByClassName('UploadBtn')[0].style.visibility='visible';
    document.getElementById('BildBorder').style.zIndex=0;
    document.getElementById('titelbild-icon').style.backgroundColor='#686767';
    document.getElementById('titelbild-icon-nav').style.backgroundColor='#686767';
    document.getElementsByClassName("output_image_style")[1].style.visibility="visible"; 
    
}
export function delete_firebase_Image(){
    let state = false;
    document.getElementsByClassName('output_image_style')[1].style.visibility="hidden";
    state = true;
    return state;
}

export function delete_image_event(){
    document.getElementById("output_image").style.visibility='hidden';
    document.getElementById('UploadBtn').style.visibility='visible';
    document.getElementById('BildBorder').style.zIndex=0;
    rechange_color('TitelbildIconNav','TitelbildIconInput');
    
}

export var image;
export function preview_image(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('output_image');
        var outputVorschau = document.getElementById('output_image_Vorschau');
        output.src = reader.result;
        outputVorschau.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    document.getElementsByClassName("output_image_style")[1].style.visibility="hidden"; 
    document.getElementById("output_image").style.visibility='visible';
    document.getElementsByClassName('UploadBtn')[0].style.visibility='hidden';
    // document.getElementById('UploadBtn').style.visibility='hidden';
    document.getElementById('BildBorder').style.zIndex=1;
    change_color('titelbild-icon','titelbild-icon-nav');
}

export function preview_logo(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('logoImg');
        output.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById('logoImg').style.visibility="visible";
    document.getElementsByClassName('LogoUploadBtn')[0].style.visibility="hidden";
    change_color('logoIconDiv','logo-icon-nav')
    
}
export function preview_image_event(event,i) {
    var reader = new FileReader();
    reader.onload = function(){
        var output = document.getElementById('output_image');
        output.src = reader.result;

    }
    reader.readAsDataURL(event.target.files[0]);
    document.getElementById("output_image").style.visibility='visible';
    document.getElementById('UploadBtn').style.visibility='hidden';
    document.getElementById('BildBorder').style.zIndex=1;
    change_color('TitelbildIconNav','TitelbildIconInput');
}


export default {change_color,rechange_color,preview_image,delete_image,preview_logo,delete_logo,delete_image_event};
